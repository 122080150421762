import {
  Container,
  Modal,
  Box,
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Card,
  CardContent,
  CardActions,
  Stack,
  ButtonGroup,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Table,
  TableContainer,
} from "@mui/material";
import React, { useState, useRef, useEffect, useContext } from "react";
import useDragger from "./useDragger";
import { useNavigate, useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase-config";
import html2canvas from "html2canvas";
import DeleteIcon from "@mui/icons-material/Delete"; // Import the Delete icon
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";
import LoadingPage from "../../pages/LoadingPage";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { ProviderContext } from "../layout/providerCtx";

const StoneNew = ({
  slabDetails,
  divList,
  setDivList,
  setStoneDetails,
  stoneDetails,
  sinkPic,
  cooktopPic,
}) => {
  const { height, width, stone_color, id, sinkCut, cookTopCut } = slabDetails;
  let uid = id + new Date().getTime();
  const [isFlipped, setIsFlipped] = useState(false);

  const toggleFlipped = () => {
    setIsFlipped(!isFlipped);
  };

  useDragger(uid);

  // const maxDimension = 3500; // Maximum dimension in mm
  // const scaleFactor = 1; // Experiment with different values

  // // Calculate scaled dimensions
  // const scaledHeight = (height / maxDimension) * 1500 * scaleFactor;
  // const scaledWidth = (width / maxDimension) * 1500 * scaleFactor;

  // const translatedWidth = isFlipped ? scaledHeight / 8 : scaledWidth / 8;
  // const translatedHeight = isFlipped ? scaledWidth / 8 : scaledHeight / 8;
  // const flexFlipDirection = isFlipped ? 'column' : 'row';

  const translatedWidth = isFlipped ? height / 6 : width / 6;
  const translatedHeight = isFlipped ? width / 6 : height / 6;
  // const flexFlipDirection = isFlipped ? 'column' : 'row';
  const flexFlipDirection = "column";

  const handleDelete = () => {
    const updatedDivList = divList.filter((stone) => stone.id !== id);
    const updatedStoneDetails = stoneDetails.filter((stone) => stone.id !== id);
    setStoneDetails(updatedStoneDetails);
    setDivList(updatedDivList);
  };

  const iconSize = Math.min(translatedWidth, translatedHeight) * 0.15; // Adjust this factor as needed

  return (
    <div
      id={uid}
      className="box"
      style={{
        height: `${translatedHeight}px`,
        width: `${translatedWidth}px`,
        backgroundColor: stone_color,
        opacity: 0.8,
        border: "1px solid black",
        position: "absolute",
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: flexFlipDirection,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 2,
          right: 2,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <DeleteIcon
          onClick={handleDelete}
          sx={{
            fontSize: 18,
            backgroundColor: "rgba(240, 240, 240, 0.7)",
            color: "red",
          }}
        />
        <Rotate90DegreesCcwIcon
          onClick={toggleFlipped}
          sx={{ fontSize: 18, backgroundColor: "rgba(240, 240, 240, 0.5)" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="button"
          sx={{
            border: "2px solid black",
            borderRadius: "5px",
            opacity: 0.6,
            backgroundColor: "white",
            fontSize: 12,
            margin: 1,
          }}
        >
          {id}
        </Typography>

        {sinkCut !== "None" && (
          <Typography
            variant="button"
            style={{
              border: "2px solid black",
              borderRadius: "5px",
              opacity: 0.6,
              backgroundColor: "white",
              fontSize: 12,
              margin: 1,
            }}
          >
            Sink Cut
          </Typography>
        )}
        {cookTopCut !== "None" && (
          <Typography
            variant="button"
            style={{
              border: "2px solid black",
              borderRadius: "5px",
              opacity: 0.6,
              backgroundColor: "white",
              fontSize: 12,
              margin: 1,
            }}
          >
            Cooktop Cut
          </Typography>
        )}
        {/* <Typography variant="subtitle2" sx={{ fontWeight: 'bold', fontSize: iconSize * 0.6, color: 'black' }}>
            {isFlipped ? `${height} x ${width}` : `${width} x ${height}`}
          </Typography> */}
      </div>
    </div>
  );
};

let imgURL =
  "https://media.istockphoto.com/id/1273109788/vector/coming-soon-isolated-vector-icon-paper-style-promotion-sign-start-a-new-business-design.jpg?s=612x612&w=0&k=20&c=0APH6QCc371SuCEYLspgp6oh-tE5-rvbK0dzLMRmJGA=";

const edge_types = [
  {
    value: "Single Edge",
    label: "Single Edge",
    price: 1,
  },
  {
    value: "Double Edge",
    label: "Double Edge",
    price: 1.5,
  },
  {
    value: "Mitred Apron",
    label: "Mitred Apron",
    price: 2,
  },
];

const edge_profiles = [
  {
    value: "Pencil",
    label: "Pencil",
    price: 20,
  },
  {
    value: "Bull Nose",
    label: "Bull Nose",
    price: 100,
  },
  {
    value: "Shark Nose",
    label: "Shark Nose",
    price: 100,
  },
  {
    value: "Splayed",
    label: "Splayed",
    price: 100,
  },
];

const sink_cutouts = [
  { value: "None", label: "None", price: 0 },
  { value: "Drop In Sink", label: "Drop In Sink", price: 20 },
  {
    value: "Undermount Single Bowl",
    label: "Undermount Single Bowl",
    price: 100,
  },
  {
    value: "Undermount Double Bowl",
    label: "Undermount Double Bowl",
    price: 200,
  },
  // { value: 'undermount_twin_polished', label: 'Undermount Twin Sinks (Polished Cut Out)', price: 20 },
  // { value: 'flushmount_polished', label: 'Flushmount Sink (Polished Cut Out)', price: 20 }
];

const cooktop_cutouts = [
  { value: "None Cooktop", label: "None", price: 0 },
  { value: "Drop In Cooktop", label: "Drop In Cooktop", price: 20 },
  { value: "Flushmount Cooktop", label: "Flushmount Cooktop", price: 200 },
];

const other_variables = [
  { value: "waterfall", label: "Waterfall", price: 200 },
  { value: "splashback", label: "Splash Back", price: 50 },
  { value: "holes", label: "Holes", price: 5 },
];

const stone_categories = [
  "Bench Top Piece",
  "Island or Bar Piece",
  "Drop Panel Waterfall",
  "Splash Back Piece",
];

const extract_price_from_db = (data, category, desiredLabel) =>
  ((selectedItem) =>
    selectedItem !== undefined ? parseFloat(selectedItem) || 0 : null)(
    data[category]?.[desiredLabel]
  );

const extract_label = (data, desiredValue) => {
  const selectedItem = data.find(
    (item) => item.label === desiredValue || item.value === desiredValue
  );

  if (selectedItem) {
    const label = selectedItem.label;
    console.log(`Label for value '${desiredValue}' is '${label}'`);
    return label;
  } else {
    console.log(`Value '${desiredValue}' not found in the array.`);
  }

  return null;
};

const StoneDetailCatcher = (props) => {
  const type_of_stone = props.type_of_stone;
  const prices = props.prices;
  const userId = props.slabAdminId;
  const [rectW, setRectW] = useState(0);
  const [rectH, setRectH] = useState(0);
  const [name, setName] = useState("");
  const [edgeType, setEdgeType] = useState("Single Edge");
  const [edgeProfile, setEdgeProfile] = useState("Pencil");
  const [sinkCut, setSinkCut] = useState("None");
  const [cookTopCut, setCookTopCut] = useState("None");
  const [splashback, setSplashback] = useState(false);
  const [waterfall, setWaterfall] = useState(false);
  const [holes, setHoles] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [initialSlabPrice, setInitialSlabPrice] = useState(1);
  const [open, setOpen] = useState(false);
  const [modalErr, setModalErr] = useState(true);

  const [slabDetails, setSlabDetails] = useState({});
  const [slabPrice, setSlabPrice] = useState(0);

  const [frontendImages, setFrontendImages] = useState([]);
  const [loadingFrontendImages, setLoadingFrontendImages] = useState(true);

  useEffect(() => {
    const fetchImages = async () => {
      const storage = getStorage();
      const storageRef = ref(storage, `Frontend/Images/StoneProps/${userId}`);

      try {
        // List all items (images) in the folder
        const result = await listAll(storageRef);

        // Get download URLs for each item and create an array of objects with name and URL
        const images = await Promise.all(
          result.items.map(async (item) => ({
            name: item.name,
            url: await getDownloadURL(item),
          }))
        );

        // Set the image array in the state
        setFrontendImages(images);
      } catch (error) {
        // Handle errors, e.g., folder not found
        console.error("Error fetching images:", error);
      } finally {
        // Set loading status to false when images are fetched
        setLoadingFrontendImages(false);
      }
    };

    fetchImages();
  }, [userId]);

  const getImageUrlByName = (imageName) => {
    if (loadingFrontendImages) {
      // If still loading, return a fallback URL
      return imgURL;
    }

    const image = frontendImages.find((img) => img.name === imageName);
    return image ? image.url : imgURL;
  };

  // const initial_slab_price = props.slab_details?.price
  const slab_color = props.slab_details?.color;
  const size = props.slab_details?.size;
  const slab_color_val = props.slab_details?.colorValue
    ? props.slab_details.colorValue
    : "green";

  useEffect(() => {
    const checkForErrors = () => {
      if (name === "none" || name === "" || rectW == 0 || rectH == 0) {
        setModalErr(true);
      } else {
        setModalErr(false);
      }
    };

    checkForErrors();
  }, [name, rectW, rectH]);

  useEffect(() => {
    if (type_of_stone == "Splash Back Piece") {
      setSplashback(true);
    }
    if (type_of_stone == "Drop Panel Waterfall") {
      setWaterfall(true);
    }
  }, []);

  useEffect(() => {
    const slabArea = props.slab_details?.dimensions
      ?.split("x")
      .reduce((product, dimension) => product * parseInt(dimension, 10), 1);
    // const quantity = (slabArea)/(3200*1600)
    const quantity_proportion = (rectW * rectH) / slabArea;
    const roundedQuantity =
      quantity_proportion % 1 > 0.45
        ? Math.ceil(quantity_proportion)
        : quantity_proportion;
    const roundedQuantityWithDecimal = Number(roundedQuantity.toFixed(2));

    setQuantity(roundedQuantityWithDecimal);
    console.log("quantity");
    console.log(quantity);
    const initial_slab_price = props.slab_details?.price;
    setInitialSlabPrice(initial_slab_price);
  }, [props.slab_details, rectW, rectH]);

  useEffect(() => {
    const edgeTypePrice = extract_price_from_db(prices, "Edge Type", edgeType);
    const edgeProfilePrice = extract_price_from_db(
      prices,
      "Edge Profile",
      edgeProfile
    );
    const sinkCutPrice = extract_price_from_db(prices, "Sink Cutout", sinkCut);
    const cookTopCutPrice = extract_price_from_db(
      prices,
      "Cooktop Cutout",
      cookTopCut
    );
    // other_variables
    const waterfallPrice = extract_price_from_db(prices, "Others", "waterfall");
    const splashbackPrice = extract_price_from_db(
      prices,
      "Others",
      "splashback"
    );
    const holesPrice = extract_price_from_db(prices, "Others", "holes");

    console.log(
      "edgeTypePrice, edgeProfilePrice, sinkCutPrice, cookTopCutPrice"
    );
    console.log(edgeType, edgeProfile, sinkCut, cookTopCut);
    console.log(edgeTypePrice, edgeProfilePrice, sinkCutPrice, cookTopCutPrice);

    console.log("waterfallPrice", "splashbackPrice", "holesPrice");
    console.log(waterfallPrice, splashbackPrice, holesPrice);

    const price =
      Number(Math.ceil(quantity)) * Number(initialSlabPrice) +
      2 * (edgeTypePrice * rectW) +
      2 * (edgeTypePrice * rectH) +
      2 * (edgeProfilePrice * rectH) +
      2 * (edgeProfilePrice * rectW) +
      sinkCutPrice +
      cookTopCutPrice +
      Number(holesPrice * holes) +
      Number(waterfall * waterfallPrice) +
      Number(splashback * splashbackPrice);

    console.log("price of this much");
    console.log(price);

    setSlabPrice(price);
  }, [
    slabDetails,
    quantity,
    edgeType,
    edgeProfile,
    sinkCut,
    cookTopCut,
    holes,
    waterfall,
    splashback,
  ]);

  useEffect(() => {
    let profile_of_slab = {
      name: name,
      width: rectW,
      height: rectH,
      edgeType: edgeType,
      edgeProfile: edgeProfile,
      sinkCut: sinkCut,
      cookTopCut: cookTopCut,
      holes: holes,
      waterfalls: waterfall,
      splashbacks: splashback,
      quantity: quantity,
      initial_slab_price: initialSlabPrice,
      calculated_slab_price: slabPrice,
      stone_color: slab_color_val,
      id: name,
    };

    console.log("profile_of_slab");
    console.log(profile_of_slab);
    console.log("prices");
    console.log(prices);
    setSlabDetails(profile_of_slab);
  }, [
    name,
    rectW,
    rectH,
    edgeType,
    edgeProfile,
    sinkCut,
    cookTopCut,
    holes,
    waterfall,
    splashback,
    slabPrice,
    quantity,
  ]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    // reset form
    setRectW(0);
    setRectH(0);
    setName("");
    setEdgeType("Single Edge");
    setEdgeProfile("Pencil");
    setSinkCut("None");
    setCookTopCut("None");
  };

  const handleEdgeTypeChange = (event) => {
    setEdgeType(event.target.value);
  };

  const handleEdgeProfileChange = (event) => {
    setEdgeProfile(event.target.value);
  };

  const onAddBtnClick = () => {
    // if (rectW > rectH) {
    //     alert(`Width (${rectH}) can't be more than Depth (${rectW}).\nYou can exchange these values.`);
    //     return;
    // }
    // alert(222)
    props.setDivList(
      props.divList.concat(
        <StoneNew
          stoneDetails={props.stoneDetails}
          setStoneDetails={props.setStoneDetails}
          slabDetails={slabDetails}
          divList={props.divList}
          setDivList={props.setDivList}
          sinkPic={getImageUrlByName("Sketch-Sink on Sketch")}
          cooktopPic={getImageUrlByName("Sketch-Cooktop on Sketch")}
        />
      )
    );
    // alert(JSON.stringify(slabDetails))
    // alert(slabPrice)
    props.setStoneDetails([...props.stoneDetails, slabDetails]);

    handleClose();
  };

  const modal_style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
    maxHeight: "80vh",
    display: "flex",
    flexDirection: "column",
  };
  const [filteredEdgeTypes, setFilteredEdgeTypes] = useState(edge_types);
  const [filteredEdgeProfile, setFilteredEdgeProfile] = useState(edge_profiles);
  const [filteredSinkCut, setFilteredSinkCut] = useState(sink_cutouts);
  const [filteredCooktop, setFilteredCooktop] = useState(cooktop_cutouts);

  useEffect(() => {
    if (prices["Offering"]) {
      setFilteredEdgeTypes(
        edge_types.filter((edge) => prices["Offering"][edge.value])
      );
      setFilteredEdgeProfile(
        edge_profiles.filter((edge) => prices["Offering"][edge.value])
      );
      setFilteredSinkCut(
        sink_cutouts.filter((edge) => prices["Offering"][edge.value])
      );
      setFilteredCooktop(
        cooktop_cutouts.filter((edge) => prices["Offering"][edge.value])
      );
    }
  }, [prices["Offering"]]);

  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        {type_of_stone}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modal_style}>
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            Create a Stone
          </Typography>
          <FormControl>
            <TextField
              type="number"
              required
              id="box-height"
              label="Piece Depth (mm)"
              variant="standard"
              onChange={(e) => setRectW(e.target.value)}
            />
            <TextField
              type="number"
              required
              id="box-width"
              label="Piece Width (mm)"
              variant="standard"
              onChange={(e) => setRectH(e.target.value)}
            />
            <TextField
              required
              id="box-id"
              label="Piece Description"
              variant="standard"
              onChange={(e) => setName(e.target.value)}
            />

            <FormLabel id="edge-type-radio">Edge Type</FormLabel>
            <RadioGroup
              onChange={handleEdgeTypeChange}
              row
              aria-labelledby="edge-type-radio-button-group"
              name="edge-type-radio"
              value={edgeType}
            >
              {filteredEdgeTypes.map((edge) => {
                return (
                  <Paper elevation={4} sx={{ m: 1, p: 1 }}>
                    <FormControlLabel
                      key={edge.label}
                      value={edge.label}
                      control={<Radio />}
                      label={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={getImageUrlByName(`Edge Type-${edge.label}`)}
                            alt={`Edge Type ${edge.label}`}
                            style={{
                              marginRight: 8,
                              height: 100,
                              width: 100,
                              borderRadius: "5%",
                              objectFit: "cover",
                            }}
                          />
                          {edge.label}
                        </div>
                      }
                    />
                  </Paper>

                  // <FormControlLabel value={edge['value']} control={<Radio />} label={edge['label']} />
                );
              })}
            </RadioGroup>

            <FormLabel id="edge-profile-radio">Edge Profile</FormLabel>
            <RadioGroup
              onChange={handleEdgeProfileChange}
              row
              aria-labelledby="edge-profile-radio-group-label"
              name="edge-profile-radio-group"
              value={edgeProfile}
            >
              {filteredEdgeProfile.map((edge) => {
                return (
                  <Paper elevation={4} sx={{ m: 1, p: 1 }}>
                    <FormControlLabel
                      value={edge.label}
                      control={<Radio />}
                      label={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={getImageUrlByName(
                              `Edge Profile-${edge.label}`
                            )}
                            alt={`Edge Profile ${edge.label}`}
                            style={{
                              marginRight: 8,
                              height: 100,
                              width: 100,
                              borderRadius: "5%",
                              objectFit: "cover",
                            }}
                          />
                          {edge.label}
                        </div>
                      }
                    />
                  </Paper>
                  // <FormControlLabel value={edge['value']} control={<Radio />} label={edge['label']} />
                );
              })}
            </RadioGroup>
            {type_of_stone === "Bench Top Piece" ||
            type_of_stone === "Island or Bar Piece" ? (
              <>
                <FormLabel id="edge-profile-radio">Sink Cutout</FormLabel>
                <RadioGroup
                  onChange={(e) => setSinkCut(e.target.value)}
                  row
                  aria-labelledby="edge-profile-radio-group-label"
                  name="edge-profile-radio-group"
                  value={sinkCut}
                >
                  {filteredSinkCut.map((edge) => {
                    return (
                      <Paper elevation={4} sx={{ m: 1, p: 1 }}>
                        <FormControlLabel
                          value={edge.label}
                          control={<Radio />}
                          label={
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={getImageUrlByName(
                                  `Sink Cutout-${edge.label}`
                                )}
                                alt={`Sink Cutout ${edge.label}`}
                                style={{
                                  marginRight: 8,
                                  height: 100,
                                  width: 100,
                                  borderRadius: "5%",
                                  objectFit: "cover",
                                }}
                              />
                              {edge.label}
                            </div>
                          }
                        />
                      </Paper>
                      // <FormControlLabel value={edge['label']} control={<Radio />} label={edge['label']} />
                    );
                  })}
                </RadioGroup>

                <FormLabel id="edge-profile-radio">Cooktop Cutout</FormLabel>
                <RadioGroup
                  onChange={(e) => setCookTopCut(e.target.value)}
                  row
                  aria-labelledby="edge-profile-radio-group-label"
                  name="edge-profile-radio-group"
                  value={cookTopCut}
                >
                  {filteredCooktop.map((edge) => {
                    return (
                      <FormControlLabel
                        value={edge.label}
                        control={<Radio />}
                        label={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={getImageUrlByName(
                                `Cooktop Cutout-${edge.label}`
                              )}
                              alt={`Cooktop Cutout ${edge.label}`}
                              style={{
                                marginRight: 8,
                                height: 100,
                                width: 100,
                                borderRadius: "5%",
                                objectFit: "cover",
                              }}
                            />
                            {edge.label}
                          </div>
                        }
                      />

                      // <FormControlLabel value={edge['label']} control={<Radio />} label={edge['label']} />
                    );
                  })}
                </RadioGroup>
              </>
            ) : null}
            <FormLabel component="legend">Drilled Hole (Tap etc) +</FormLabel>
            <TextField
              variant="standard"
              value={holes}
              onChange={(e) => setHoles(e.target.value)}
            />

            <Button
              disabled={modalErr}
              onClick={onAddBtnClick}
              fullWidth
              variant="contained"
              sx={{ my: 2 }}
            >
              {modalErr
                ? "Please Write a name for stone and add width and height of stone to add"
                : "Add Stone to Sketch"}
            </Button>
          </FormControl>
        </Box>
      </Modal>
    </>
  );
};

const GridCanvas = (props) => {
  return (
    <div
      id="parent_slabs"
      className="container"
      style={{
        backgroundImage: "url('/grid/grid3.png')",
        backgroundRepeat: "repeat",
        backgroundPosition: "center",
        backgroundSize: "200px",
        opacity: 0.9,
        height: "1200px",
        width: "1200px",
        margin: "auto",
        display: "inline-block",
        overflow: "auto",
        // position: 'relative', overflow: 'hidden',
      }}
    >
      {props.divList}
    </div>
  );
};

const StoneDrawer = () => {
  const navigate = useNavigate();
  const { stone_Id } = useParams();
  const { setBrandId } = useContext(ProviderContext);

  const [divList, setDivList] = useState([]);
  const [stoneDetails, setStoneDetails] = useState([]);
  const [slabDetails, setSlabDetails] = useState([]);
  const [install, setInstall] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    const fetchStoneDetails = async () => {
      let uploadedBy = "";
      try {
        const StoneRef = doc(db, "stones", `${stone_Id}`);
        const StoneSnap = await getDoc(StoneRef);

        if (StoneSnap.exists()) {
          console.log("Document data:", StoneSnap.data());
          setSlabDetails(StoneSnap.data());
          uploadedBy = StoneSnap.data().uploadedBy;
          setBrandId(uploadedBy);
          console.log("uploadedBy: ");
          console.log(uploadedBy);
        } else {
          console.log("No such document for stone!");
        }

        const PriceRef = doc(db, "Brands", uploadedBy);
        const PriceSnap = await getDoc(PriceRef);

        if (PriceSnap.exists()) {
          console.log("Document data:", PriceSnap.data());
          setPrices(PriceSnap.data());
        } else {
          console.log("No such document for price!");
        }

        setLoading(false);
      } catch (error) {
        console.log(error);
        setError(true);
        alert("Error");
      }
    };
    fetchStoneDetails();
  }, [stone_Id]);
  const handleGetQuoteDrawing = async () => {
    const element = document.getElementById("parent_slabs");
    let canvas = await html2canvas(element, { useCORS: true });
    let stonePlan = canvas.toDataURL("image/jpg");
    console.log(stonePlan);
    // navigate('/quote/adjust', { state: {stones: stoneDetails, slab: slabDetails, stonePlan: stonePlan, install: install } })
    navigate("/quote/address", {
      state: {
        stones: stoneDetails,
        slab: slabDetails,
        stonePlan: stonePlan,
        stoneId: stone_Id,
        install: install,
      },
    });
  };

  return (
    <Box>
      {/* <div style={{width: '70vw', overflow: 'auto'}}>
            <p>{divList.length}</p>
            {JSON.stringify(divList)}
            </div>
            <div style={{width: '70vw', overflow: 'auto'}}>
            <p>{stoneDetails.length}</p>
            {JSON.stringify(stoneDetails)}
            </div> */}

      {loading ? (
        <LoadingPage />
      ) : (
        <Box p={2}>
          <Card
            elevation={3}
            sx={{
              p: 1,
              m: 1,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Box>
                  <Typography variant="h6">Selected Slab Details: </Typography>
                  <Stack spacing={0.25}>
                    <Typography variant="body2">
                      Stone/Slab:{" "}
                      {slabDetails?.name ? slabDetails.name : "Not Specified"}
                    </Typography>
                    <Typography variant="body2">
                      Brand:{" "}
                      {slabDetails?.brand ? slabDetails.brand : "Not Specified"}
                    </Typography>
                    <Typography variant="body2">
                      Collection/Range:{" "}
                      {slabDetails?.range ? slabDetails.range : "Not Specified"}
                    </Typography>
                    <Typography variant="body2">
                      Slab Price:{" "}
                      {slabDetails?.price ? slabDetails.price : "Not Specified"}
                    </Typography>
                    <Typography variant="body2">
                      Size of Slab:{" "}
                      {slabDetails?.size ? slabDetails.size : "Not Specified"}
                    </Typography>
                    <Typography variant="body2">
                      Slab Dimensions (mm):{" "}
                      {slabDetails?.dimensions
                        ? slabDetails.dimensions
                        : "Not Specified"}
                    </Typography>
                    {/* <Typography variant="body2">
                      Slab Area:{" "}
                      {slabDetails?.dimensions
                        ? slabDetails.dimensions
                            ?.split("x")
                            .reduce(
                              (product, dimension) =>
                                product * parseInt(dimension, 10),
                              1
                            )
                        : "Not Specified"}
                    </Typography> */}
                    <Typography variant="body2">
                      Color:{" "}
                      {slabDetails?.color ? slabDetails.color : "Not Specified"}
                    </Typography>
                    {/* <Typography variant="body2">Uploaded By: {slabDetails?.uploadedBy? slabDetails.uploadedBy: "Not Specified"}</Typography>
                                        <Typography variant="body2">Prices: {JSON.stringify(prices)}</Typography> */}
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box>
                  <Typography variant="h6" sx={{ textAlign: "center" }}>
                    Create Stones Structure
                  </Typography>
                  <Stack spacing={1}>
                    {stone_categories.map((stone) => {
                      return (
                        <StoneDetailCatcher
                          // testing to show right price
                          // price={slabDetails?.price }
                          // size={slabDetails?.size }
                          slab_details={slabDetails}
                          prices={prices}
                          // to access waterfall or splashback
                          type_of_stone={stone}
                          slabAdminId={slabDetails?.uploadedBy}
                          // to update stones list drew on grid / canvas
                          stoneDetails={stoneDetails}
                          setStoneDetails={setStoneDetails}
                          divList={divList}
                          setDivList={setDivList}
                        />
                      );
                    })}
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Card>

          <div
            style={{
              border: "5px solid black",
              position: "relative",
              overflow: "auto",
              width: "100%",
              height: "90vh",
              //display: "flex", justifyContent: "center", alignItems: "center"
            }}
          >
            <GridCanvas divList={divList} />
          </div>

          {/* <Box sx={{my: 5}}>
                        <Typography variant="h6" sx={{fontWeight: "bold"}}>Below table shown when you add a stone in sketch, is only for development purposes.</Typography>
                        <Typography variant="h6" sx={{fontWeight: "bold"}}>This will help you cross match prices and tell me if I am using the price algorithm wrong way.</Typography>
                        { stoneDetails && stoneDetails.length>0 && <StonesTable stoneDetails={stoneDetails}/>}
                    </Box> */}

          {/* installed? */}
          <FormControlLabel
            control={
              <Checkbox
                checked={install}
                onChange={() => setInstall(!install)}
              />
            }
            label="Do you also want to install this piece?"
          />
          {/* <Typography onClick={handleGetQuoteDrawing} sx={{backgroundColor: "black", color: 'white', p:2, my:2, textAlign: "center", width: "fit-content", cursor: "pointer"}}>Get Your Quote</Typography> */}
          <Typography
            onClick={handleGetQuoteDrawing}
            sx={{
              backgroundColor: "black",
              color: "white",
              p: 2,
              my: 2,
              textAlign: "center",
              width: "fit-content",
              cursor: "pointer",
            }}
          >
            Get Your Quote
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default StoneDrawer;

const StonesTable = ({ stoneDetails }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell variant="h6">Stone Name</TableCell>
            <TableCell variant="body1">Dimensions (w x h)</TableCell>
            {/* <TableCell variant="body1">
            Slab Size
            </TableCell> */}
            <TableCell variant="body1">Edge type</TableCell>
            <TableCell variant="body1">Edge Profile</TableCell>
            <TableCell variant="body1">Sink Cut</TableCell>
            <TableCell variant="body1">Cook Top Cut</TableCell>
            <TableCell variant="body1">Waterfall</TableCell>
            <TableCell variant="body1">Splash Back</TableCell>
            <TableCell variant="body1">Holes</TableCell>
            <TableCell variant="body1">Quantity</TableCell>
            {/* <TableCell variant="body1">
            Slab Price
            </TableCell> */}
            <TableCell variant="body1">Calculated Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stoneDetails &&
            stoneDetails.map((stone) => {
              return <StoneRow stone={stone} />;
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StoneRow = ({ stone }) => {
  return (
    <TableRow p={1} m={1} sx={{ border: "1px solid black" }}>
      <TableCell variant="h6">{stone.name}</TableCell>
      <TableCell variant="body1">
        {stone.width} x {stone.height}
      </TableCell>
      {/* <TableCell variant="body1">
            slab size
            </TableCell> */}
      <TableCell variant="body1">
        {/* {stone.edgeType},  */}
        {extract_label(edge_types, stone.edgeType)}
      </TableCell>
      <TableCell variant="body1">
        {extract_label(edge_profiles, stone.edgeProfile)}
      </TableCell>
      <TableCell variant="body1">
        {extract_label(sink_cutouts, stone.sinkCut)}
      </TableCell>
      <TableCell variant="body1">
        {extract_label(cooktop_cutouts, stone.cookTopCut)}
      </TableCell>
      <TableCell variant="body1">{stone.waterfall ? "Yes" : "No"}</TableCell>
      <TableCell variant="body1">
        {stone["splashback"] ? "Splash" : "No"}
      </TableCell>
      <TableCell variant="body1">{stone.holes}</TableCell>
      <TableCell variant="body1">{stone?.quantity}</TableCell>
      {/* <TableCell variant="body1">
            {stone.initial_slab_price}
            </TableCell> */}
      <TableCell variant="body1">{stone?.calculated_slab_price}</TableCell>
    </TableRow>
  );
};
